import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '../atoms/Text';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'gatsby';

const Container = styled.div``;

const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;

    .input-label {
      position: relative;
      width: 100%;
      font-size: ${({ theme }) => theme.size14};
      font-weight: ${({ theme }) => theme.medium};

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .required-info {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        color: red;
        font-family: ${({ theme }) => theme.fontFamily};
        font-size: ${({ theme }) => theme.size14};
      }
    }
    .bottom-inputs {
      display: flex;
      flex-direction: column;
      @media (min-width: ${({ theme }) => theme.desktop}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .bottom-label {
          width: 45%;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  form input:not(#agreement),
  form select {
    margin-top: 6px;
    background-color: #f8f8f8;
    border: none;
    border-bottom: 2px solid #dfdfdf;
    outline: none;
    height: 40px;
    width: 100%;
    padding: 10px;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size14};
  }

  form textarea {
    margin-top: 10px;
    background-color: #f8f8f8;
    border: none;
    border-bottom: 2px solid #dfdfdf;
    outline: none;
    min-height: 50px;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 10px;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size14};

    @media (min-width: ${({ theme }) => theme.desktop}) {
      font-size: ${({ theme }) => theme.size15};
    }
  }

  .submit-btn {
    margin-top: 50px;
    width: 180px;
    height: 50px;
    border: none;
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.size18};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.regular};
    align-self: center;
    &:hover {
      background-color: #232a3e;
    }
  }
`;

const SubmitSuccessfull = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  width: 90%;
  max-width: 650px;
  padding: 15px 30px;
  text-align: center;
  height: 200px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  z-index: 999;
  transform: translate(-50%, -10%);
`;

const SubmitText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.white};
`;

const SubmitButton = styled.button`
  margin-top: 30px;
  width: 180px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.size18};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.regular};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.black};
    background-color: ${({ theme }) => theme.white};
  }
`;

const Agreement = styled.div`
  position: relative;
  display: flex;
  input {
    position: absolute;
    top: 5px;
    left: 0;
    width: 15px !important;
    height: 15px !important;
  }

  label {
    padding-left: 25px;
    font-size: 12px;
  }

  .required-info {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: red;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size16};
  }

  a {
    margin-left: 2px;
  }
`;

const FindApartmentForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleCloseButton = () => {
    setFormSubmitted(false);
    setFormError(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, cartItems) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
      .post('https://formsubmit.co/ajax/e810423d7c832016206418a518560311', {
        RodzajNieruchomości: data.RodzajNieruchomości,
        IloscPokoi: data.IloscPokoi,
        Powierzchnia: data.Powierzchnia,
        Lokalizacja: data.Lokalizacja,
        Text: data.Text,
        Imie: data.Imie,
        Telefon: data.Telefon,
        Email: data.Email,
      })
      .then((response) => {
        if (response.status === 200) {
          setFormSubmitted(true);
          setFormError(false);
        } else {
          setFormSubmitted(false);
          setFormError(true);
        }
      })
      .catch((error) => {
        setFormSubmitted(false);
        setFormError(true);
      });
  };
  return (
    <Container>
      {formSubmitted && (
        <SubmitSuccessfull>
          <SubmitText>
            Dziękujemy za skorzystanie z formularza ! <br />
            Skontaktujemy się z Państwem tak szybko jak to tylko możliwe !
          </SubmitText>
          <SubmitButton onClick={handleCloseButton}>Zamknij</SubmitButton>
        </SubmitSuccessfull>
      )}

      {formError && (
        <SubmitSuccessfull>
          <SubmitText>
            Niestety wystąpił błąd! Prosimy o wysłanie wiadomości bezpośrednio
            na nasz adres email.
          </SubmitText>
          <SubmitButton onClick={handleCloseButton}>Zamknij</SubmitButton>
        </SubmitSuccessfull>
      )}
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className='input-label'>
            Przeznaczenie
            <select {...register('RodzajNieruchomości', { required: true })}>
              <option value='Mieszkanie dla siebie'>
                Mieszkanie dla siebie
              </option>
              <option value='Mieszkanie na wynajem'>
                Mieszkanie na wynajem
              </option>
              <option value='Inne'>Inne</option>
            </select>
            {errors.RodzajNieruchomości && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Powierzchnia
            <input
              placeholder='Powierzchnia'
              {...register('Powierzchnia', { required: true })}
            />
            {errors.Powierzchnia && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Lokalizacja
            <input
              placeholder='Lokalizacja'
              {...register('Lokalizacja', { required: true })}
            />
            {errors.Lokalizacja && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Napisz na czym Ci najbardziej zależy
            <textarea
              cols='10'
              rows='2'
              maxLength='1000'
              placeholder='Napisz na czym Ci najbardziej zależy'
              {...register('Text', { required: true })}
            />
            {errors.Text && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>

          <label className='input-label'>
            Imię
            <input
              placeholder='Imię'
              {...register('Imie', { required: true })}
            />
            {errors.Imie && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Telefon
            <input
              placeholder='Telefon'
              {...register('Telefon', { required: true })}
            />
            {errors.Telefon && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Adres e-mail
            <input
              placeholder='Adres e-mail'
              {...register('Email', { required: true })}
            />
            {errors.Email && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>

          <Agreement>
            <label for='agreement'>
              Wypełnienie formularza oznacza, że podane w nim dane osobowe będą
              przetwarzane w celu przesłania oferty oraz kontaktu w jej sprawie.
              Zasady przetwarzania i informacje o administratorze znajdziesz
              <Link to='/polityka-prywatnosci-i-cookies'>tutaj.</Link>
              <input
                type='checkbox'
                id='agreement'
                name='agreement'
                {...register('Agreement', { required: true })}
              />
            </label>
            {errors.Agreement && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </Agreement>

          <button type='submit' className='submit-btn'>
            Wyślij
          </button>
        </form>
      </FormContainer>
    </Container>
  );
};

export default FindApartmentForm;

import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import FindApartment from '../../components/organisms/FindApartment';

const ApartmentProjects = ({ location }) => {
  const { state = {} } = location;
  if (state == undefined) {
    const { isPricing } = false;
    return (
      <MainTemplate
        isMainPage={false}
        title='Wyszukanie i stworzenie mieszkania zgodnie z oczekiwaniami'
        metaDescription='Dokonamy oceny i analizy zarówno umowy deweloperskiej, cesji umowy
      deweloperskiej jak i umowy Sprzedaży nieruchomości (gruntowej,
      budynkowej i lokalowej)'
        isDark
      >
        <FindApartment isPricing={isPricing} />
      </MainTemplate>
    );
  } else {
    const { isPricing } = state;
    return (
      <MainTemplate
        isMainPage={false}
        title='Wyszukanie i stworzenie mieszkania zgodnie z oczekiwaniami'
        metaDescription='Dokonamy oceny i analizy zarówno umowy deweloperskiej, cesji umowy
      deweloperskiej jak i umowy Sprzedaży nieruchomości (gruntowej,
      budynkowej i lokalowej)'
        isDark
      >
        <FindApartment isPricing={isPricing} />
      </MainTemplate>
    );
  }
};

export default ApartmentProjects;
